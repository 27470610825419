<template>
  <div>
    <div class="subHeader base noFlex">
      <div class="module-header">
        <h2>결제정보</h2>
      </div>
      <div class="module-config">
        <div class="wtBox config-list">
          <div class="box form">
            <div class="flex">
              <p>이름</p>
              <span>{{ checkoutData_buyer_name }}</span>
            </div>
            <div class="flex">
              <p>이메일</p>
              <span>{{ checkoutData_buyer_email }}</span>
            </div>
            <div class="flex">
              <p>전화번호</p>
              <span>{{ checkoutData_buyer_tel }}</span>
            </div>
            <div class="flex">
              <p>결제금액</p>
              <span>{{ comma(checkoutData_paid_amount) }} 원</span>
            </div>
            <div class="flex">
              <p>충전 포인트</p>
              <span>{{ checkoutData_total_point }} P</span>
            </div>
            <div class="flex" v-show="checkoutData_status == 'cancelled'">
              <p>환불일</p>
              <span>{{
                moment(checkoutData_updated_at).format("YYYY년 MM월 DD일")
              }}</span>
            </div>
            <div class="flex">
              <p>결제카드</p>
              <span>{{ checkoutData_card_name }}</span>
            </div>
            <div class="flex">
              <p>카드번호</p>
              <span>{{ checkoutData_card_num }}</span>
            </div>
            <div class="flex">
              <p>결제일</p>
              <span>{{
                moment(checkoutData_date).format("YYYY년 MM월 DD일")
              }}</span>
            </div>
            <div class="flex">
              <p>상태</p>
              <span
                :class="{
                  success: checkoutData_status == 'paid',
                  error: checkoutData_status == 'paid_fail',
                  primary: checkoutData_status == 'refund',
                  orange: checkoutData_status == 'cancelled',
                  error: checkoutData_status == 'norefund',
                }"
              >
                {{
                  checkoutData_status == "paid"
                    ? "결제 완료"
                    : checkoutData_status == "paid_fail"
                    ? "결제 실패"
                    : checkoutData_status == "refund"
                    ? "환불 신청"
                    : checkoutData_status == "cancelled"
                    ? "환불 완료"
                    : checkoutData_status == "norefund"
                    ? "환불 거절"
                    : ""
                }}
              </span>
            </div>
            <!-- 상태가 환불 신청인 사용자 클릭 시 -->
            <div v-show="checkoutData_status != 'paid'">
              <div class="flex">
                <p>환불 종류</p>
                <span>
                  {{
                    checkoutData_refund_category == "etc"
                      ? "기타"
                      : checkoutData_refund_category == "money"
                      ? "이용 요금이 비싸서"
                      : checkoutData_refund_category == "usage"
                      ? "사용 빈도가 낮아서"
                      : ""
                  }}
                </span>
              </div>
              <div
                class="flex content"
                v-show="
                  checkoutData_refund_category &&
                  checkoutData_refund_category == 'etc'
                "
              >
                <p>사유</p>
                <pre>{{ checkoutData_refund_reason }}</pre>
              </div>
              <div class="flex refund">
                <div
                  class="flex content"
                  v-if="checkoutData_status == 'refund'"
                >
                  <p>환불금액</p>
                  <input
                    type="number"
                    placeholder="금액을 입력해주세요."
                    :value="checkoutData_refund_amount"
                    @input="handleChange_refundAmount"
                  />
                </div>
                <div class="flex" v-if="checkoutData_status == 'cancelled'">
                  <p>환불금액</p>
                  <span>{{ checkoutData_refund_payment }} 원</span>
                </div>
                <div class="buttons" v-if="checkoutData_status == 'refund'">
                  <button @click="handel_refundAgree">승인</button>
                  <button @click="handel_refundApproval">거절</button>
                </div>
                <span
                  class="primary"
                  v-show="checkoutData_status == 'cancelled'"
                  >승인처리 되었습니다.</span
                >
                <span class="error" v-show="checkoutData_status == 'norefund'"
                  >거절처리 되었습니다.</span
                >
              </div>
              <div class="flex" v-show="checkoutData_status == 'refund'">
                <p>차감할 포인트</p>
                <span>{{ checkoutData_refund_point }} P</span>
                <P></P>
                <p>+ 추가 포인트</p>
                <span>{{ checkoutData_plus_point }} P</span>
              </div>
              <div class="flex" v-show="checkoutData_status == 'cancelled'">
                <p>차감된 포인트</p>
                <span>{{ checkoutData_refund_total_point }} P</span>
              </div>
            </div>
          </div>
          <div class="buttonWrap">
            <router-link to="/admin/payments">
              <button class="right line">목록</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
import moment from "moment";
import { format } from "@/mixins/format";
import {
  fetchUserCheckoutDetail,
  updateRefundApproval,
  updateRefundAgree,
  userPointCheck,
} from "@/api/index";

export default {
  mixins: [format],
  name: "adminPaymentDetail",
  data() {
    return {
      moment: moment,
      checkoutId: "", // 상품 인덱스 아이디
      checkoutData_userId: "", // 상품의 유저 아이디
      checkoutData_buyer_name: "", // 바이어 이름
      checkoutData_buyer_email: "", // 바이어 이메일
      checkoutData_buyer_tel: "", // 바이어 전화번호
      checkoutData_status: "", // 상태
      checkoutData_date: "", // 결제일
      checkoutData_updated_at: "", // 환불일
      checkoutData_serviceType: "", // 결제상품
      checkoutData_paid_amount: 0, // 결제금액
      checkoutData_point: 0, //  포인트
      checkoutData_plus_point: 0, //  플러스 포인트
      checkoutData_total_point: 0, //  충전포인트
      checkoutData_card_name: "", // 카드이름
      checkoutData_card_num: "", // 카드번호
      checkoutData_refund_category: "", // 환불 종류
      checkoutData_refund_reason: "", // 환불 사유
      checkoutData_refund_amount: 0, // 환불 금액
      checkoutData_refund_point: 0, // 환불 금액
      checkoutData_refund_payment: 0,
      checkoutData_refund_total_point: 0,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
    this.checkoutId = this.$route.params.checkoutId;
  },
  mounted() {
    this.getUserCheckoutDetail(this.$route.params.checkoutId);
  },
  methods: {
    // 환불 승인
    async handel_refundAgree() {
      if (
        this.checkoutData_refund_amount == "" ||
        this.checkoutData_refund_amount == 0
      ) {
        this.checkoutData_refund_amount = 0;
        this.checkoutData_refund_point = 0;
        return alert("입력 오류입니다. 다시 입력해주세요.");
      }
      let params = {
        userId: this.checkoutData_userId,
        point: this.checkoutData_refund_point + this.checkoutData_plus_point,
      };
      let data = {
        id: this.checkoutId,
        paid_amount: this.checkoutData_refund_amount,
        point: this.checkoutData_refund_point + this.checkoutData_plus_point,
      };
      // user 포인트 체크
      await userPointCheck(params).then((res) => {
        if (res.data.status != 200) {
          return alert(`${res.data.message}`);
        }
      });
      // user 환불 승인
      await updateRefundAgree(data).then((res) => {
        if (res.data.status == 200) {
          this.getUserCheckoutDetail(this.checkoutId);
        } else {
          return alert("서버 에러가 발생했습니다.");
        }
      });
      return this.getUserCheckoutDetail(this.checkoutId);
    },
    // 환불 거절
    handel_refundApproval() {
      let data = {
        id: this.checkoutId,
      };
      updateRefundApproval(data).then((res) => {
        if (res.data.status == 200) {
          this.getUserCheckoutDetail(this.checkoutId);
        } else {
          return alert("서버 에러가 발생했습니다.");
        }
      });
    },
    // 금액 / 포인트 계산
    handleChange_refundAmount(e) {
      this.checkoutData_refund_point = 0;
      let paid_amountNotTen = Math.ceil(this.checkoutData_paid_amount / 1.1);
      this.checkoutData_refund_amount =
        e.target.value.split("")[0] == "0"
          ? e.target.value.split("").splice(1).join("")
          : e.target.value;
      // 금액 계산
      if (this.checkoutData_refund_amount < 0) {
        this.checkoutData_refund_amount = 0;
      } else if (
        this.checkoutData_refund_amount >= this.checkoutData_paid_amount
      ) {
        this.checkoutData_refund_amount = this.checkoutData_paid_amount;
      }
      // 포인트 계산
      if (this.checkoutData_refund_amount >= paid_amountNotTen) {
        this.checkoutData_refund_point = this.checkoutData_point;
      } else {
        this.checkoutData_refund_point = Math.floor(
          this.checkoutData_refund_amount / 100
        );
      }
    },
    // 유저 체크아웃 정보 가져오기
    getUserCheckoutDetail(id) {
      let params = {
        id: id,
      };
      fetchUserCheckoutDetail(params).then((res) => {
        this.checkoutData_userId = res.data.checkoutData.userId;
        this.checkoutData_buyer_name = res.data.checkoutData.buyer_name;
        this.checkoutData_buyer_email = res.data.checkoutData.buyer_email;
        this.checkoutData_buyer_tel = res.data.checkoutData.buyer_tel;
        this.checkoutData_status = res.data.checkoutData.status;
        this.checkoutData_date = res.data.checkoutData.created_at;
        this.checkoutData_updated_at = res.data.checkoutData.updated_at;
        this.checkoutData_serviceType = res.data.checkoutData.serviceType;
        this.checkoutData_paid_amount = res.data.checkoutData.paid_amount;
        if (res.data.checkoutData.point && res.data.checkoutData.point[0]) {
          this.checkoutData_point = res.data.checkoutData.point[0].point;
          this.checkoutData_plus_point =
            res.data.checkoutData.point[0].plus_point;
          this.checkoutData_total_point = res.data.checkoutData.total_point;
        }
        this.checkoutData_card_name = res.data.checkoutData.card_name;
        this.checkoutData_card_num = res.data.checkoutData.card_num;
        this.checkoutData_refund_category =
          res.data.checkoutData.refund_category;
        this.checkoutData_refund_reason = res.data.checkoutData.refund_reason;
        if (
          res.data.checkoutData.refund_amount &&
          res.data.checkoutData.refund_amount > 0
        ) {
          this.checkoutData_refund_payment =
            res.data.checkoutData.refund_amount;
          this.checkoutData_refund_total_point =
            res.data.checkoutData.point[1].point;
        }
      });
    },
  },
};
</script>
